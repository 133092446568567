import { produce } from 'immer';
import { StateCreator } from 'zustand';
import { User } from './domain';

export type Slice = {
    auth: {
        setUser: (user: User) => void;
        user: User;
        setToken: (token: string) => void;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    auth: {
        user: {
            account: null,
            accessToken: '',
            authRequest: { scopes: [] },
            image: '',
            permissions: [],
            email: '',
            name: '',
        },
        setUser: (user: User) =>
            set(
                produce((draft: Slice) => {
                    draft.auth.user = user;
                })
            ),
        setToken: (token: string) =>
            set(
                produce((draft: Slice) => {
                    draft.auth.user.accessToken = token;
                })
            ),
    },
});
