import { produce } from 'immer';
import { StateCreator } from 'zustand';
import { Market } from './domain';

export type Slice = {
    market: {
        setMarkets: (markets: Market[]) => void;
        markets: Market[];
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    market: {
        markets: [],
        setMarkets: (markets: Market[]) =>
            set(
                produce((draft: Slice) => {
                    draft.market.markets = markets;
                })
            ),
    },
});
