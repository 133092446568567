import { useStore } from '@store';
import { produce } from 'immer';

const http = async <T>(path: string, config: RequestInit): Promise<T> => {
    const idToken = useStore.getState().auth.user.accessToken;

    const secureConfig = idToken
        ? produce(config, draft => {
              draft.headers = { ...draft.headers, Authorization: `Bearer ${idToken}` };
          })
        : config;

    const request = new Request(path, secureConfig);
    const response = await fetch(request);

    if (!response.ok) {
        switch (response.status) {
            case 401:
                throw new Error(response.statusText, {
                    cause: response.status,
                });
            case 400:
                throw new Error('badRequest', {
                    cause: await response.json(),
                });
            case 404:
                throw new Error('notFound', {
                    cause: response.status,
                });
            case 409:
                throw new Error('conflict', {
                    cause: response.status,
                });
            default:
                throw new Error('errorMessageGenericAction', {
                    cause: response.status,
                });
        }
    }

    return response.json().catch(() => {});
};

const defaultConfig: RequestInit = {
    headers: {
        'Content-Type': 'application/json',
    },
};

type Agent = {
    get: <T>(path: string, signal?: AbortSignal) => Promise<T>;
    post: <T, U>(path: string, body?: T, signal?: AbortSignal) => Promise<U>;
    postWithoutBody: <U>(path: string, signal?: AbortSignal) => Promise<U>;
    postFormData: <T, U>(path: string, body: T, signal?: AbortSignal) => Promise<U>;
    put: <T, U>(path: string, body: T, signal?: AbortSignal) => Promise<U>;
    delete: <T>(path: string, signal?: AbortSignal) => Promise<T>;
    patch: <T, U>(path: string, body?: T, signal?: AbortSignal) => Promise<U>;
};

const wait = (duration: number) => {
    return new Promise(resolve => setTimeout(resolve, duration));
};

const getUrl = (apiUrl: string, path: string) => `${apiUrl}${path}`;

export { defaultConfig, getUrl, http, wait, type Agent };
