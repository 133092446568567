import '@assets/styles/global.scss';
import '@assets/styles/scrollbar.scss';
import '@assets/styles/typography.scss';
import { configureAppInsights } from '@features/logging';
import { Settings } from '@features/settings';
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { useStore } from '@store';
import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApplicationRoutes } from './routing';

type Props = {
    settings: Settings;
};

const App = ({ settings }: Props) => {
    const { reactPlugin } = configureAppInsights(settings.appInsights.key);
    const { setApiUrls } = useStore(state => state.settings);

    useEffect(() => {
        setApiUrls(settings.apiUrl, settings.apiAiUrl);
    }, []);

    return (
        <AppInsightsErrorBoundary onError={() => <>ERROR</>} appInsights={reactPlugin}>
            <AppInsightsContext.Provider value={reactPlugin}>
                <Router>
                    <ApplicationRoutes authSettings={settings.auth} />
                </Router>
            </AppInsightsContext.Provider>
        </AppInsightsErrorBoundary>
    );
};

export { App };
