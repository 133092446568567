import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useStore } from '@store';
import { useEffect, useState } from 'react';
import { executeTokenAdquisition, getMeInfo } from './api';

export const useExecuteAuthenticationFlow = () => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { setUser, setToken, user } = useStore(state => state.auth);
    const [isLoadingAuthentication, setIsLoadingAuthentication] = useState(true);

    useEffect(() => {
        (async () => {
            if (isAuthenticated && inProgress === 'none') {
                const result = await executeTokenAdquisition(instance, user.authRequest.scopes);
                setToken(result?.accessToken || '');
                const meInfo = await getMeInfo();

                setIsLoadingAuthentication(false);

                setUser({
                    ...user,
                    accessToken: result?.accessToken || '',
                    account: result?.account || null,
                    image: meInfo?.image || '',
                    permissions: [],
                    email: meInfo?.email || '',
                    name: meInfo?.name || '',
                });
            }
        })();
    }, [isAuthenticated, inProgress]);

    return { isLoadingAuthentication };
};
