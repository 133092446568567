import { MsalProvider } from '@azure/msal-react';
import { getMsalInstance } from '@features/auth';
import '@features/internationalization/i18n';
import { getSettings } from '@features/settings';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';

const settings = getSettings();

createRoot(document.getElementById('root') as HTMLElement).render(
    <StrictMode>
        <MsalProvider instance={getMsalInstance(settings.auth)}>
            <App settings={settings} />
        </MsalProvider>
    </StrictMode>
);
