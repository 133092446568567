import { Slice as AuthSlice, createSlice as createAuthSlice } from '@features/auth/slice';
import { createSlice as createMarketSlice, Slice as MarketSlice } from '@features/market/slice';
import { createSlice as createSettingsSlice, Slice as SettingsSlice } from '@features/settings/slice';

import { create, StoreApi } from 'zustand';
import { devtools } from 'zustand/middleware';

export type StoreState = AuthSlice & SettingsSlice & MarketSlice;

export type StoreSlice<T> = (set: StoreApi<T>['setState'], get: StoreApi<T>['getState']) => T;

export const useStore = create<StoreState>()(
    devtools((...all) => ({
        ...createAuthSlice(...all),
        ...createSettingsSlice(...all),
        ...createMarketSlice(...all),
    }))
);
