import { AuthenticationResult, EventMessage, EventType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { isEdge, isFirefox, isSafari } from '@shared/constants';
import { useStore } from '@store';
import { produce } from 'immer';

type AuthSettings = {
    clientId: string;
    authority: string;
    redirectUri: string;
    postLogoutRedirectUri: string;
    scopes: string[];
};

const EVENTS_THAT_REQUIRES_SET_ACTIVE_ACCOUNT =
    EventType.LOGIN_SUCCESS || EventType.SSO_SILENT_SUCCESS || EventType.ACQUIRE_TOKEN_SUCCESS;

const msalSetup = (msalInstance: PublicClientApplication, authSettings: AuthSettings) => {
    useStore.setState(state =>
        produce(state, draft => {
            draft.auth.user.authRequest.scopes = authSettings.scopes;
        })
    );

    const activeAccount = msalInstance.getActiveAccount();

    if (!activeAccount && msalInstance.getAllAccounts().length > 0) {
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0] ?? null);
    }

    useStore.setState(state =>
        produce(state, draft => {
            draft.auth.user.account = activeAccount;
        })
    );

    msalInstance.enableAccountStorageEvents();

    msalInstance.addEventCallback((message: EventMessage) => {
        const payload = message.payload as AuthenticationResult;
        if (EVENTS_THAT_REQUIRES_SET_ACTIVE_ACCOUNT.includes(message.eventType) && payload.account) {
            const account = payload.account;
            msalInstance.setActiveAccount(account);
            useStore.setState(state =>
                produce(state, draft => {
                    draft.auth.user.account = payload.account;
                    draft.auth.user.accessToken = payload.accessToken;
                })
            );
        }
    });
};

const msalSettings = (settings: AuthSettings) => ({
    auth: {
        clientId: settings.clientId,
        authority: settings.authority,
        redirectUri: settings.redirectUri,
        postLogoutRedirectUri: settings.postLogoutRedirectUri,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isEdge || isFirefox || isSafari,
    },
    loggerOptions: {
        loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
            if (containsPii) {
                return;
            }
            switch (level) {
                case LogLevel.Error:
                    console.error(message);
                    return;
                case LogLevel.Info:
                    console.info(message);
                    return;
                case LogLevel.Verbose:
                    console.debug(message);
                    return;
                case LogLevel.Warning:
                    console.warn(message);
                    return;
                default:
                    return;
            }
        },
    },
});

const getMsalInstance = (settings: AuthSettings) => {
    const msalInstance = new PublicClientApplication(msalSettings(settings));
    msalSetup(msalInstance, settings);

    return msalInstance;
};

export { getMsalInstance, type AuthSettings };
