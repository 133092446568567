import { produce } from 'immer';
import { StateCreator } from 'zustand';

export type Slice = {
    settings: {
        setApiUrls: (apiUrl: string, apiAiUrl: string) => void;
        apiUrl: string;
        apiAiUrl: string;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    settings: {
        apiUrl: '',
        apiAiUrl: '',
        setApiUrls: (apiUrl: string, apiAiUrl: string) =>
            set(
                produce((draft: Slice) => {
                    draft.settings.apiUrl = apiUrl;
                    draft.settings.apiAiUrl = apiAiUrl;
                })
            ),
    },
});
