import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { PublicLandingPage } from '@pages/lazyLoadingPages';
import { Outlet } from 'react-router-dom';

type Props = {
    scopes: string[];
    isLoadingAuthentication: boolean;
};

const RouteGuard = ({ scopes, isLoadingAuthentication }: Props) =>
    isLoadingAuthentication ? (
        <UnauthenticatedTemplate>
            <PublicLandingPage scopes={scopes} />
        </UnauthenticatedTemplate>
    ) : (
        <AuthenticatedTemplate>
            <Outlet />
        </AuthenticatedTemplate>
    );

export { RouteGuard };
